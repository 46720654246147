import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';

import {lastValueFrom, map} from 'rxjs';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import ImageryLayer from '@arcgis/core/layers/ImageryLayer';
import TimeSlider from "@arcgis/core/widgets/TimeSlider";
import MapView from '@arcgis/core/views/MapView';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Bookmarks from '@arcgis/core/widgets/Bookmarks';
import Home from '@arcgis/core/widgets/Home';
import Compass from "@arcgis/core/widgets/Compass.js";
import Expand from '@arcgis/core/widgets/Expand';
import LayerList from '@arcgis/core/widgets/LayerList';
import LayerSearchSource from '@arcgis/core/widgets/Search/LayerSearchSource';
import Search from '@arcgis/core/widgets/Search';
import Map from '@arcgis/core/Map';
import * as typeRendererCreator from '@arcgis/core/smartMapping/renderers/type';
import uniqueValues from '@arcgis/core/smartMapping/statistics/uniqueValues';
import FeatureTable from '@arcgis/core/widgets/FeatureTable';
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import ClassBreaksRenderer from "@arcgis/core/renderers/ClassBreaksRenderer";
import Color from "@arcgis/core/Color";
import { ChartService } from 'src/app/core/service/chart.service';
import { SplitComponent, SplitAreaDirective} from 'angular-split'
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions } from '@angular/material/legacy-tooltip';
import {Device, Site} from '@model';
import {SiteService, ResourceService} from "@service";
import {Router} from "@angular/router";

/** Custom options to configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 300,
  hideDelay: 300,
  touchendHideDelay: 300,
};

@Component({
  selector: 'premo-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }],
})

export class MapViewComponent implements AfterViewInit, OnDestroy {

  public view: any = null;
  public base_url = '';
  public mode: string = 'grid';
  // public bottomSplit = [50, 50];
  public geojson_url_blob: string = '';
  public values = [];
  public selectedFeature = null;
  public id = 0;

  // The <div> where we will place the map
  @ViewChild('mapViewNode', { static: true }) private mapViewEl!: ElementRef;
  @ViewChildren(SplitComponent) splitEl!: SplitComponent;
  @ViewChild(SplitAreaDirective) areasEl!: SplitAreaDirective;
  @ViewChild('secondDialog') private secondDialog!: TemplateRef<any>;
  @ViewChild('thirdDialog') private thirdDialog!: TemplateRef<any>;

  textboxValue: string = '';
  protected _sites: Site[];
  protected featureTableShown = true;
  constructor(
    private chartService: ChartService,
    private http: HttpClient,
    private dialog: MatDialog,
    private resourceService: ResourceService<Device>,
  ) {
    this.base_url = window.location.origin + resourceService.apiLocation;
  }

  @Input() set sites(sites: Site[] | null) {
    if (!sites) return;
    this._sites = JSON.parse(JSON.stringify(sites));
  }

  get sites(): Site[] {
    return this._sites;
  }

  ngAfterViewInit(): void {
    this.setToGridMode();
    this.initializeMap().then(() => {
      console.log('The map is ready.');
    });
  };

  getGeoJSONdata_async(geojson_url: string): Promise<string> {
    const request$ = this.http.get(geojson_url).pipe(
        map((response: any) => {
          const blob = new Blob([JSON.stringify(response.data)], {type: "application/json"});
          return URL.createObjectURL(blob);
        })
    );
    return lastValueFrom(request$);
  };

  selectionChange(): void {
    if (!this.featureTableShown) {
      this.expand_bottom();
    } else {
      this.close_bottom();
    }
    this.featureTableShown = !this.featureTableShown;
  };

  close_bottom() {
    this.areasEl.collapse(105);
  };

  expand_bottom() {
    this.areasEl.expand();
  };

  fillSiteIdTextbox(siteId: string) {
    let siteIdText = document.getElementById('siteId') as HTMLInputElement;
    siteIdText.value = '';
    siteIdText.value = siteId;
  }

  updateTSChart() {
    let siteIdText = document.getElementById('siteId') as HTMLInputElement;
    if (siteIdText.value === '') {
      this.dialog.open(this.secondDialog);
    }
    else if (this.selectedFeature.attributes.state != 'Ready'){
      this.dialog.open(this.thirdDialog);
    }
    else {
      this.setToPlotMode();
      this.chartService.updateChart(siteIdText.value);
    }
  };

  clearChart() {
    this.chartService.updateChart('');
  };

  // resetChart() {
  //   this.chartService.updateChart('');
  // };

  setToGridMode() {
    this.mode = 'grid';
    let tableWrapper = document.getElementById('tableWrapper') as HTMLDivElement;
    let chartWrapper = document.getElementById('chartWrapper') as HTMLDivElement;
    chartWrapper.style.display = 'none';
    chartWrapper.style.height = '0%';
    tableWrapper.style.display = '';
    tableWrapper.style.height = '100%';
  };

  setToPlotMode() {
    this.mode = 'plot';
    let tableWrapper = document.getElementById('tableWrapper') as HTMLDivElement;
    let chartWrapper = document.getElementById('chartWrapper') as HTMLDivElement;
    tableWrapper.style.height = '0%';
    tableWrapper.style.display = 'none';
    chartWrapper.style.display = '';
    chartWrapper.style.height = '100%';
  };

  async initializeMap(): Promise<any> {
    console.log('Map Initialized');
    console.log(this.base_url);

    let contents = `
    <p><b>{kind}</b> model with <b>{model_mae}</b> Mean Absolute Error (MAE) and <b>{model_mape}</b> Mean Absolute Percent Error (MAPE).
    </p>
    <ul>
      <li><b>Site ID:</b> {id}</li>
      <li><b>Forecasted Device ID:</b> {f_deviceId}</li>
      <li><b>Site Name:</b> {name}</li>
      <li><b>Address:</b> {address}</li>
      <li><b>Forecaster ID:</b> {forecaster_id}</li>
      <li><b>Model Kind:</b> {kind}</li>
      <li><b>Device Type:</b> {device_name}</li>
      <li><b>Model created on:</b> {created_ts}</li>
      <li><b>Model last forecasted on:</b> {last_forecasted_ts}</li>
      <li><b>Model last trained on:</b> {last_trained_ts}</li>
      <li><b>Model last forecasted duration (sec):</b> {last_forecasted_duration}</li>
      <li><b>Utility:</b> {utility}</li>
      <li><b>Commissioned:</b> {commissioned}</li>
    <ul>
    `;

    const template_forecast_kind = {
      title: 'DER.OS Site: {name}',
      outFields: ['*'],
      fieldInfos: [{
        fieldName: 'model_mae',
        visible: true,
        format: {
          places: 2,
        },
      }, {
        fieldName: 'model_mape',
        visible: true,
        format: {
          places: 2,
        },
      }],
      content:
        [
          {
            type: 'text',
            text: contents,
          },
        ],
    };

    const template_forecast = {
      title: '{name}',
      content: [
        {
          type: 'fields',
          fieldInfos: [
            {
              fieldName: 'name',
              label: 'Name',
            },
            {
              fieldName: 'id',
              label: 'Site ID',
            },
            {
              fieldName: 'f_deviceId',
              label: 'Forecasted Device ID',
            },
            {
              fieldName: 'forecaster_id',
              label: 'Forecaster ID',
            },
            {
              fieldName: 'forecaster_name',
              label: 'Forecaster Name',
            },
            {
              fieldName: 'kind',
              label: 'Forecaster Kind',
            },
            {
              fieldName: 'device_id',
              label: 'Device ID',
            },
            {
              type: 'field',
              fieldName: 'device_name',
              label: 'Device Type',
            },
            {
              fieldName: 'machine_name',
              label: 'Machine Name',
            },
            {
              fieldName: 'address',
              label: 'Address',
            },
            {
              fieldName: 'commissioned',
              label: 'Commissioned',
            },
            {
              fieldName: 'state',
              label: 'Forecaster State',
            },
            {
              fieldName: 'kwargs',
              label: 'Forecaster Optional kwargs',
            },
            {
              fieldName: 'last_forecasted_ts',
              label: 'Last Forecasted TS',
            },
            {
              fieldName: 'last_forecasted_duration',
              label: 'Last Forecasted Duration (sec)',
            },
            {
              fieldName: 'last_stochastic_update_ts',
              label: 'Last Stochastic Update TS',
            },
            {
              fieldName: 'last_trained_ts',
              label: 'Last Trained TS',
            },
            {
              fieldName: 'created_ts',
              label: 'Created TS',
            },
            {
              fieldName: 'model_mae',
              label: 'Model MAE',
            },
            {
              fieldName: 'model_mape',
              label: 'Model MAPE',
            },
            {
              fieldName: 'model_persistance',
              label: 'Model Persistance',
            },
            {
              fieldName: 'sid',
              label: 'SID',
            },
            {
              fieldName: 'device_sid',
              label: 'Device SID',
            },
          ],
        },
      ],
    };

    const template_nerc = {
      title: '{NAME} at {ADDRESS}',
      content: [
        {
          type: 'fields',
          fieldInfos: [
            {
              fieldName: 'NAME',
              label: 'Name',
            },
            {
              fieldName: 'ADDRESS',
              label: 'Address',
            },
            {
              fieldName: 'CITY',
              label: 'City',
            },
            {
              fieldName: 'WEBSITE',
              label: 'URL',
            },
          ],
        },
      ],
    };

    const template_us_wildfires = {
      title: "{IncidentName}: {POOCounty}, {POOState}",
      content: [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "IncidentName",
              label: "Incident Name"
            },
            {
              fieldName: "IncidentTypeCategory",
              label: "Incident Type Category"
            },
            {
              fieldName: "UniqueFireIdentifier",
              label: "Unique Fire Identifier"
            },
            {
              fieldName: "DailyAcres",
              label: "Daily Acres"
            },
            {
              fieldName: "CalculatedAcres",
              label: "Calculated Acres"
            },
            {
              fieldName: "PercentContained",
              label: "Percent Contained"
            },
            {
              fieldName: "ICS209ReportDateTime",
              label: "ICS 209 Report DateTime"
            },
            {
              fieldName: "FireDiscoveryDateTime",
              label: "Fire Discovery DateTime"
            },
            {
              fieldName: "DiscoveryAcres",
              label: "Discovery Acres"
            },
            {
              fieldName: "POOCounty",
              label: "POO County"
            },
            {
              fieldName: "POOState",
              label: "POO State"
            },
            {
              fieldName: "FireCause",
              label: "Fire Cause"
            },
            {
              fieldName: "FireCauseGeneral",
              label: "Fire Cause General"
            },
            {
              fieldName: "GACC",
              label: "GACC"
            },
            {
              fieldName: "TotalIncidentPersonnel",
              label: "Total Incident Personnel"
            },
            {
              fieldName: "IncidentManagementOrganization",
              label: "Incident Management Organization"
            },
            {
              fieldName: "FireMgmtComplexity",
              label: "Fire Mgmt Complexity"
            },
            {
              fieldName: "ResidencesDestroyed",
              label: "Residences Destroyed"
            },
            {
              fieldName: "OtherStructuresDestroyed",
              label: "Other Structures Destroyed"
            },
            {
              fieldName: "Injuries",
              label: "Injuries"
            },
            {
              fieldName: "PredominantFuelGroup",
              label: "Predominant Fuel Group"
            },
            {
              fieldName: "PredominantFuelModel",
              label: "Predominant Fuel Model"
            },
            {
              fieldName: "PrimaryFuelModel",
              label: "Primary Fuel Model"
            },
            {
              fieldName: "ContainmentDateTime",
              label: "Containment Date Time"
            },
            {
              fieldName: "ControlDateTime",
              label: "Control Date Time"
            },
            {
              fieldName: "FinalAcres",
              label: "Final Acres"
            },
            {
              fieldName: "IsValid",
              label: "Is Valid"
            },
            {
              fieldName: "FireOutDateTime",
              label: "Fire Out Date Time"
            },
            {
              fieldName: "ModifiedOnDateTime",
              label: "Modified On Date Time"
            },
            {
              fieldName: "IncidentTypeKind",
              label: "Incident Type Kind"
            },
            {
              fieldName: "IrwinID",
              label: "IrwinID"
            },
            {
              fieldName: "GlobalID",
              label: "GlobalID"
            },
            {
              fieldName: "ModifiedOnAge",
              label: "Modified On Age"
            },
            {
              fieldName: "FireDiscoveryAge",
              label: "Fire Discovery Age"
            }
          ]
        }
      ]
    };

    const template_events_ordered_by_size = {
      title: '{Event} - Severity: {Severity}, KinUrgencyd: {Urgency}',
      content: [
        {
          type: 'fields',
          fieldInfos: [
            {
              fieldName: 'OBJECTID',
              label: 'OBJECTID',
            },
            {
              fieldName: 'Event',
              label: 'Event',
            },
            {
              fieldName: 'Severity',
              label: 'Severity',
            },
            {
              fieldName: 'Summary',
              label: 'Summary',
            },
            {
              fieldName: 'Link',
              label: 'Link',
            },
            {
              fieldName: 'Urgency',
              label: 'Urgency',
            },
            {
              fieldName: 'Certainty',
              label: 'Certainty',
            },
            {
              fieldName: 'Category',
              label: 'Category',
            },
            {
              fieldName: 'Updated',
              label: 'Updated',
            },
            {
              fieldName: 'Start',
              label: 'Start',
            },
            {
              fieldName: 'End_',
              label: 'End',
            },
            {
              fieldName: 'Affected',
              label: 'Affected',
            },
          ],
        },
      ],
    };

    const template_rain_accumulation = {
      title: 'NDFD Precipitation v1 - Cumulative Total: {label}',
      content: [
        {
          type: 'fields',
          fieldInfos: [
            {
              fieldName: 'category',
              label: 'Category',
            },
            {
              fieldName: 'fromdate',
              label: 'From Date',
            },
            {
              fieldName: 'todate',
              label: 'To Date',
            },
          ],
        },
      ],
    };

    const template_streamgauge = {
      title: 'Station: {stationid}',
      content: [
        {
          type: 'fields',
          fieldInfos: [
            {
              fieldName: 'status',
              label: 'STATUS',
            },
            {
              fieldName: 'lastupdate',
              label: 'UPDATED',
            },
            {
              fieldName: 'todate',
              label: 'To Date',
            },
            {
              fieldName: 'stage_ft',
              label: 'Height (feet)',
            },
            {
              fieldName: 'flow_cfs',
              label: 'Flow (cfs)',
            },
            {
              fieldName: 'org',
              label: 'Organization',
            }],
        },
      ],
    };

    const template_station = {
      title: "WEATHER STATION: {STATION_NAME}",
      outFields: ["*"]
    };

    const template_transmission = {
      title: "Transmission Lines: {VOLT_CLASS}",
      content: [
        {
          type: 'fields',
          fieldInfos: [
            {
              fieldName: 'TYPE',
              label: 'TYPE',
            },
            {
              fieldName: 'VOLTAGE',
              label: 'VOLTAGE',
            },
            {
              fieldName: 'VOLT_CLASS',
              label: 'VOLT_CLASS',
            },
            {
              fieldName: 'NAICS_CODE',
              label: 'NAICS_CODE',
            },
            {
              fieldName: 'NAICS_DESC',
              label: 'NAICS_DESC',
            },
            {
              fieldName: 'OWNER',
              label: 'OWNER',
            },
            {
              fieldName: 'SOURCE',
              label: 'SOURCE',
            }],
        },
      ],
    };

    const template_bess = {
      title: "BESS Site: {Plant_Name}",
      content: [
        {
          type: 'fields',
          fieldInfos: [
            {
              fieldName: 'Utility_Name',
              label: 'Utility Name',
            },
            {
              fieldName: 'Street_Address',
              label: 'Street Address',
            },
            {
              fieldName: 'City',
              label: 'City',
            },
            {
              fieldName: 'State',
              label: 'State',
            },
            {
              fieldName: 'Zip',
              label: 'Zip',
            },
            {
              fieldName: 'tech_desc',
              label: 'Technology',
            },
            {
              fieldName: 'Install_MW',
              label: 'Install MW',
            },
            {
              fieldName: 'Total_MW',
              label: 'Total MW',
            },
            {
              fieldName: 'Bat_MW',
              label: 'Battery MW',
            }],
        },
      ],
    }


    // Get GeoJson from premo.service (await)
    this.geojson_url_blob = await this.getGeoJSONdata_async(this.base_url+'/sites/current_forecast_status?format=json');

    const geojsonLayer = new GeoJSONLayer({
      title: 'DER.OS Forecast Kind',
      url: this.geojson_url_blob,
      copyright: 'Enel X North America',
      popupTemplate: template_forecast_kind,
      visible: false,
      refreshInterval: 1
    });

    const geojsonLayer2 = new GeoJSONLayer({
      title: 'DER.OS Forecast Status',
      url: this.geojson_url_blob,
      copyright: 'Enel X North America',
      popupTemplate: template_forecast,
      visible: true,
      outFields:["*"],
      refreshInterval: 1
    });

    const bess_sites = new FeatureLayer(
        {
          title: "Battery Energy Storage System Sites",
          url: "https://services7.arcgis.com/FGr1D95XCGALKXqM/arcgis/rest/services/Battery_Storage_Plants/FeatureServer/0",
          popupTemplate: template_bess,
          visible: false,
          definitionExpression: "PrimSource = 'batteries'"
        }
    );

    const sym1 = new SimpleLineSymbol({
      cap: "round",
      color: new Color([218,204,255,1]),
      join: "round",
      miterLimit: 1,
      style: "solid",
      width: 1
    });
    const sym2 = new SimpleLineSymbol({
      cap: "round",
      color: new Color([167,137,251,1]),
      join: "round",
      miterLimit: 1,
      style: "solid",
      width: 1
    });
    const sym3 = new SimpleLineSymbol({
      cap: "round",
      color: new Color([128,85,247,1]),
      join: "round",
      miterLimit: 1,
      style: "solid",
      width: 1
    });
    const sym4 = new SimpleLineSymbol({
      cap: "round",
      color: new Color([111,60,251,1]),
      join: "round",
      miterLimit: 1,
      style: "solid",
      width: 1
    });
    const sym5 = new SimpleLineSymbol({
      cap: "round",
      color: new Color([88,31,244,1]),
      join: "round",
      miterLimit: 1,
      style: "solid",
      width: 1
    });
    const sym6 = new SimpleLineSymbol({
      cap: "round",
      color: new Color([67,0,250,1]),
      join: "round",
      miterLimit: 1,
      style: "solid",
      width: 1
    });

    const trans_renderer = new ClassBreaksRenderer ({
      field: "VOLTAGE",
      classBreakInfos: [
        {
          minValue: 0,
          maxValue: 99,
          symbol: sym1,
          label: "Under 100 volts"
        }, {
          minValue: 100,
          maxValue: 160,
          symbol: sym2,
          label: "100 - 161 volts"
        }, {
          minValue: 220,
          maxValue: 287,
          symbol: sym3,
          label: "220 - 287 volts"
        }, {
          minValue: 345,
          maxValue: 346,
          symbol: sym4,
          label: "345 volts"
        }, {
          minValue: 500,
          maxValue: 501,
          symbol: sym5,
          label: "500 volts"
        }, {
          minValue: 735,
          maxValue: 10000,
          symbol: sym6,
          label: "735 volts and above"
        }
      ]
    });

    const transmission = new FeatureLayer(
        {
          title: 'USA Transmission Lines - AC',
          url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/ArcGIS/rest/services/Transmission_Lines/FeatureServer/0',
          popupTemplate: template_transmission,
          renderer: trans_renderer,
          definitionExpression: "TYPE <> 'DC'",
          visible: false,
        }
    );

    const transmission_dc = new FeatureLayer(
        {
          title: 'USA Transmission Lines - DC',
          url: 'https://services1.arcgis.com/Hp6G80Pky0om7QvQ/ArcGIS/rest/services/Transmission_Lines/FeatureServer/0',
          popupTemplate: template_transmission,
          definitionExpression: "TYPE = 'DC'",
          visible: false,
        }
    );

    const fire_incident = new FeatureLayer(
        {
          title: 'USA Wildfires',
          url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/USA_Wildfires_v1/FeatureServer/0',
          popupTemplate: template_us_wildfires,
          visible: false,
          useViewTime: true
        }
    );

    const smoke_forecast = new FeatureLayer(
        {
          title: 'Smoke Forecast',
          url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/ArcGIS/rest/services/NDGD_SmokeForecast_v1/FeatureServer',
          visible: false,
          opacity: 0.5
        }
    );

    const precip_forecast = new FeatureLayer(
        {
          title: 'Precipitation Forecast',
          url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/ArcGIS/rest/services/NDFD_Precipitation_v1/FeatureServer',
          visible: false,
          opacity: 0.5
        }
    );

    const events_ordered_by_size = new FeatureLayer(
        {
          title: 'Events Ordered by Size and Severity',
          url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/NWS_Watches_Warnings_v1/FeatureServer/6',
          popupTemplate: template_events_ordered_by_size,
          visible: false
        }
    );

    const weather_station = new FeatureLayer(
        {
          title: 'Weather Station',
          url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/ArcGIS/rest/services/NOAA_METAR_current_wind_speed_direction_v1/FeatureServer/0',
          popupTemplate: template_station,
          visible: false,
        }
    );

    weather_station.load().then(function(){
      var query = weather_station.createQuery();
      query.returnGeometry = true;
      query.where = "1=1";

      weather_station.queryFeatures(query).then(function(results){
        let weather_contents = `
        <p>{WEATHER}: {SKY_CONDTN}</p>
        <ul>
          <li>`+results.fields[1].alias +`: {ICAO}</li>
          <li>`+results.fields[2].alias +`: {OBS_DATETIME}</li>
          <li>`+results.fields[5].alias +`: {ELEVATION}</li>
          <li>`+results.fields[6].alias +`: {TEMP}</li>
          <li>`+results.fields[8].alias +`: {R_HUMIDITY}</li>
          <li>`+results.fields[9].alias +`: {WIND_DIRECT}</li>
          <li>`+results.fields[10].alias +`: {WIND_SPEED}</li>
          <li>`+results.fields[11].alias +`: {WIND_GUST}</li>
          <li>`+results.fields[12].alias +`: {WIND_CHILL}</li>
          <li>`+results.fields[13].alias +`: {VISIBILITY}</li>
          <li>`+results.fields[14].alias +`: {PRESSURE}</li>
          <li>`+results.fields[17].alias +`: {HEAT_INDEX}</li>
        </ul>`
        weather_station.popupTemplate.content = weather_contents; //"Alias for incidentid is: " + results.fields[1].alias;
      });
    });

    const pm25Layer = new ImageryLayer({
      title: 'Hourly average PM2.5',
      visible: false,
      opacity: 0.5,
      url: "https://mapservices.weather.noaa.gov/raster/rest/services/air_quality/ndgd_apm25_hr01/ImageServer"
    });

    const weatherLayer = new MapImageLayer({
      title: 'NWS Radar Current Observation',
      visible: true,
      opacity: 0.5,
      useViewTime: true,
      url: "https://mapservices.weather.noaa.gov/eventdriven/rest/services/radar/radar_base_reflectivity/MapServer"
    });

    const weatherLayerTS = new ImageryLayer({
      title: 'NWS Radar Time Series Observation',
      visible: false,
      opacity: 0.5,
      url: "https://mapservices.weather.noaa.gov/eventdriven/rest/services/radar/radar_base_reflectivity_time/ImageServer"
    });

    const cummulativeRain = new FeatureLayer({
      title: 'The total forecast rainfall accumulation',
      visible: false,
      opacity: 0.5,
      popupTemplate: template_rain_accumulation,
      url: "https://services9.arcgis.com/RHVPKKiFTONKtxq3/ArcGIS/rest/services/NDFD_Precipitation_v1/FeatureServer/2"
    });

    const streamgauge = new FeatureLayer({
      title: 'Stream Gauge',
      visible: false,
      opacity: 0.7,
      popupTemplate: template_streamgauge,
      definitionExpression: "status = 'Major Flood' OR status = 'Moderate Flood' OR status = 'Minor Flood' OR status = 'Action Stage'",
      url: 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/Live_Stream_Gauges_v1/FeatureServer/0'
    });

    const populationLayer = new MapImageLayer({
      title: 'World Population Density 2020',
      url: "https://sedac.ciesin.columbia.edu/arcgis/rest/services/sedac/gpw_v4_population_density_rev11/MapServer",
      blendMode: "multiply",
      visible: false,
      sublayers: [
        {
          id: 4,
          title: "2020",
          visible: true
        }, {
          id: 3,
          title: "2015",
          visible: false
        }, {
          id: 2,
          title: "2010",
          visible: false
        }, {
          id: 1,
          title: "2005",
          visible: false
        }, {
          id: 0,
          title: "2000",
          visible: false
        }]
    });

    const nerc_region = new FeatureLayer({
          title: 'NERC Reliability Coordinators',
          url: "https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/NERC_Reliability_Coordinators/FeatureServer/0",
          popupTemplate: template_nerc,
          opacity: 0.4,
          visible: false
        }
    );

    const map = new Map({
      basemap: 'oceans', // See https://developers.arcgis.com/javascript/3/jsapi/esri.basemaps-amd.html
    });

    const view = new MapView({
      container: this.mapViewEl.nativeElement,
      center: [-95.71, 37.09],
      zoom: 3,
      map: map,
      constraints: {
        rotationEnabled: true,
      },
      popup: {
        dockEnabled: true,
        dockOptions: {
          buttonEnabled: true,
          breakpoint: false,
        },
        visibleElements: {
          closeButton: true,
          heading: true,
          actionBar: true,
          collapseButton: true
        }
      },
    });

    let compass = new Compass({
      view: view
    });
    view.ui.add(compass, "top-left");

    const homeWidget = new Home({
      view: view
    });
    view.ui.add(homeWidget, 'top-left');

    const layerList = new LayerList({
      view: view,
      listItemCreatedFunction: (event) => {
        const item = event.item;
        if (item.layer.type != 'group') {
          // don't show legend twice
          item.panel = {
            content: 'legend',
            open: true,
          };
        }
      },
    });

    const expandLyr = new Expand({
      view: view,
      content: layerList,
      expanded: false,
    });
    view.ui.add(expandLyr, 'top-left');

    let customSearchSource = new LayerSearchSource(
      {
        layer: geojsonLayer,
        searchFields: ['id', 'name', 'f_deviceId'],
        suggestionTemplate: 'Name: {name}, Site ID: {id}',
        displayField: 'name',
        exactMatch: false,
        outFields: ['*'],
        zoomScale: 500000,
        name: 'DER.OS Devices',
        placeholder: 'vsc13-soak',
      },
    );

    let searchWidget = new Search({
      view: view,
      sources: [customSearchSource],
    });
    view.ui.add(searchWidget, {
      position: 'top-right',
    });

    // Add a basemap Gallery
    const bg = new BasemapGallery({
      view: view,
      container: document.createElement('div'),
    });
    const expand = new Expand({
      view: view,
      content: bg,
    });
    view.ui.add(expand, 'top-right');

    const bookmarks = new Bookmarks({
      view: view,
      dragEnabled: true,
      visibleElements: {
        addBookmarkButton: true,
        editBookmarkButton: true,
        time: false // don't show the time (h:m:s) next to the date
      }
    });
    const bkExpand = new Expand({
      view: view,
      content: bookmarks,
      expanded: false
    })
    view.ui.add(bkExpand, "top-right");


    // time slider widget initialization
    const timeSlider = new TimeSlider({
      view: view,
      container: document.createElement("div"),
      mode: "time-window",
      timeVisible: true,
      loop: true,
      actions: [
        {
          id: "Precipitation Forecast",
          icon: "clock",
          title: "Set to Precipitation Forecast"
        },
        {
          id: "Smoke Forecast",
          icon: "clock",
          title: "Set to Smoke Forecast"
        },
        {
          id: "USA Wildfires",
          icon: "clock",
          title: "Set to USA Wildfires"
        },
        {
          id: "NWS Radar Time Series Observation",
          icon: "clock",
          title: "Set to NWS Radar Time Series Observation"
        }
      ]
    });

    timeSlider.on("trigger-action", (event) => {
      switch(event.action.id) {
        case "Precipitation Forecast":
          timeSlider.fullTimeExtent = precip_forecast.timeInfo.fullTimeExtent;
          precip_forecast.visible = true;
          timeSlider.stops = {
            interval: precip_forecast.timeInfo.interval
          };
          break;
        case "Smoke Forecast":
          timeSlider.fullTimeExtent = smoke_forecast.timeInfo.fullTimeExtent;
          smoke_forecast.visible = true;
          timeSlider.stops = {
            interval: smoke_forecast.timeInfo.interval
          };
          break;
        case "USA Wildfires":
          timeSlider.fullTimeExtent = fire_incident.timeInfo.fullTimeExtent;
          fire_incident.visible = true;
          break;
        case "NWS Radar Time Series Observation":
          timeSlider.fullTimeExtent = weatherLayerTS.timeInfo.fullTimeExtent;
          weatherLayerTS.visible = true;
          break;
      }
    });

    const tsExpand = new Expand({
      expandTooltip: "Expand Time Slider",
      view: view,
      content: timeSlider.container,
      expandIcon: timeSlider.icon,
      expanded: false
    });
    view.ui.add(tsExpand, "bottom-right");

    // Get references to div elements for toggling table visibility
    const siteIdText = document.getElementById('siteId')! as HTMLInputElement;
    const forecastState = document.getElementById('forecastState')!;
    const deviceType = document.getElementById('deviceType') as HTMLSelectElement;
    const cleartextbtn = document.getElementById('clearchart') as HTMLButtonElement;
    const filterTablebtn = document.getElementById('filterTable') as HTMLButtonElement;
    forecastState.style.width="100px";

    // OnClick event for clear button
    cleartextbtn.addEventListener('click', function() {
      clearTextbox();
      updateFeatureTable();
    });

    // OnClick event for filter button
    filterTablebtn.addEventListener('click', function() {
      updateFeatureTable();
    });

    // Create the feature table
    const featureTable = new FeatureTable({
      view: view,
      layer: geojsonLayer,
      multiSortEnabled: true,
      highlightEnabled: true,
      // visibleElements: {
      //   menuItems: {
      //     clearSelection: true,
      //     refreshData: true,
      //     toggleColumns: true,
      //     selectedRecordsShowAllToggle: true,
      //     selectedRecordsShowSelectedToggle: true,
      //     zoomToSelection: true,
      //   },
      //   selectionColumn: false,
      //   columnMenus: true,
      // },
      // actionColumnConfig: {
      //   label: "Go to site",
      //   icon: "zoom-to-object",
      //   callback: (params) => {
      //     view.goTo(params.feature);
      //   }
      // },
      tableTemplate: {
        // Autocast to TableTemplate
        columnTemplates: [
          // Takes an array of FieldColumnTemplate and GroupColumnTemplate
          {
            type: 'field',
            fieldName: 'forecaster_id',
            label: 'Forecaster ID'
          },
          {
            type: 'field',
            fieldName: 'forecaster_name',
            label: 'Forecaster Name',
          },
          {
            type: 'field',
            fieldName: 'kind',
            label: 'kind',
          },
          {
            type: 'field',
            fieldName: 'state',
            label: 'state',
          },
          {
            type: 'field',
            fieldName: 'id',
            label: 'Site ID',
          },
          {
            type: 'field',
            fieldName: 'f_deviceId',
            label: 'Forecasted Device ID',
          },
          {
            type: 'field',
            fieldName: 'device_name',
            label: 'Device Type',
          },
          {
            type: 'field',
            fieldName: 'name',
            label: 'Name',
            direction: 'asc',
          },
          {
            type: 'field',
            fieldName: 'address',
            label: 'Address',
          },
          {
            type: 'field',
            fieldName: 'device_id',
            label: 'Device ID',
            visible: false
          },
          {
            type: 'field',
            fieldName: 'machine_name',
            label: 'Machine Name',
            direction: 'asc',
          },
          {
            type: 'field',
            fieldName: 'kwargs',
            label: 'Optional Forecast kwargs',
          },
          {
            type: 'field',
            fieldName: 'created_ts',
            label: 'Created TS',
          },
          {
            type: 'field',
            fieldName: 'last_stochastic_update_ts',
            label: 'Last Stochastic Update TS',
            visible: false
          },
          {
            type: 'field',
            fieldName: 'last_trained_ts',
            label: 'Last Trained TS',
          },
          {
            type: 'field',
            fieldName: 'last_forecasted_ts',
            label: 'Last Forecasted TS'
          },
          {
            type: 'field',
            fieldName: 'commissioned',
            label: 'Commissioned',
          },
          {
            type: 'field',
            fieldName: 'sid',
            label: 'sid',
          },
          {
            type: 'field',
            fieldName: 'model_fname',
            label: 'Model File Name',
          },
          {
            type: 'field',
            fieldName: 'model_mae',
            label: 'Model MAE',
          },
          {
            type: 'field',
            fieldName: 'model_mape',
            label: 'Model MAPE',
          },
          {
            type: 'field',
            fieldName: 'model_persistance',
            label: 'Model Persistance',
          },
        ],
      },
      container: document.getElementById('tableContainer')!,
    });

    // filter the table based on forecast state drop dowb change
    forecastState.addEventListener('change', function() {
      updateFeatureTable();
    });
    // filter the table based on forecast state drop dowb change
    deviceType.addEventListener('change', function() {
      updateFeatureTable();
    });
    // filter the table based on site-id text change
    siteIdText.addEventListener('change', (event: any) => {
      updateFeatureTable();
    });

    // Watch for the popup's visible property. Once it is true, clear the current table selection and select the corresponding table row from the popup
    reactiveUtils.watch(
        () => view.popup.viewModel?.active,
        () => {
          this.selectedFeature = view.popup.selectedFeature;
          if (this.selectedFeature !== null && view.popup.visible !== false) {
            featureTable.highlightIds.removeAll();
            featureTable.highlightIds.add(view.popup.selectedFeature.attributes.OBJECTID);
            this.fillSiteIdTextbox(view.popup.selectedFeature.attributes.f_deviceId);
            this.id = this.selectedFeature.getObjectId();
          }
        }
    );

    reactiveUtils.when(
      () => view.stationary === true,
      () => {
        // Get the new extent of view/map whenever map is updated.
        if (view.extent) {
          // Filter out and show only the visible features in the feature table.
          featureTable.filterGeometry = view.extent;
        }
      }, { initial: true },
    );

    reactiveUtils.watch(
        () => view.popup.viewModel?.active,
        () => {
          let selectedFeature = view.popup.selectedFeature;
          if (selectedFeature !== null && view.popup.visible !== false) {
            featureTable.highlightIds.removeAll();
            featureTable.highlightIds.add(
                view.popup.selectedFeature.attributes.__OBJECTID
            );
            // let id = selectedFeature.getObjectId();
            featureTable.zoomToSelection();
          }
          else {
            featureTable.highlightIds.removeAll();
          }
        }
    );

    // Listen for the view's click event and access the associated graphic.
    view.on("immediate-click", (event) => {
      view.hitTest(event).then((response) => {
        console.log("Number of features: "+ response.results.length)
        // featureTable.highlightIds.removeAll();

        const sel = document.getElementById("siteId") as HTMLSelectElement;
        let options: Array<string> = [];
        // remove all the options
        for (let i = sel.options.length; i >= 0; i--) {
          sel.remove(i);
        }

        if (response.results.length === 0){
          return;
        }
        response.results.forEach((candidate1) => {
          // Check if a feature is clicked on the view.
          if (candidate1.type === "graphic"){
            const graphic = candidate1.graphic;
            var opt = document.createElement("option");
            var item = String(graphic.getAttribute("f_deviceId")).trim();
            console.log(item);

            // if item is not in options, add to opt
            if (options.includes(item) === false && item != "undefined"){
              options.push(item);
              opt.text = item;
              opt.value = item;
              sel.add(opt, null);
            }

          }
        });

      });
    });

    // This enables mouse-hovor on points and show popup.
    // Since there are multiple records at the same location, this adaptation may not work well.
    // view.on("pointer-move", (event) => {
    //   view.hitTest(event).then((response) => {
    //     const candidate = response.results[0];
    //     if (candidate.type === "graphic"){
    //       const graphic = candidate.graphic;
    //       view.popup.close();
    //       view.popup.open({
    //         location: candidate.mapPoint,
    //         features: [graphic]
    //       });
    //     }
    //     else {
    //       view.popup.close();
    //     }
    //   });
    // });

    // Watch the featureTable's highlightIds.length property,
    // and get the count of highlighted features within
    // the table.
    featureTable.watch('highlightIds.length', (ids) => {
      // Iterate through the filters within the table.
      // If the active filter is "Show selection",
      // changes made to highlightIds (adding/removing)
      // are reflected.
      featureTable.viewModel.activeFilters.forEach((filter) => {
        if (filter.type === 'selection') {
          featureTable.filterBySelection();
        }
      });
    });

    function getUniqueStatus() {
      uniqueValues({
        layer: geojsonLayer,
        field: 'state',
      }).then(function(response) {
        // prints each unique value and the count of features containing that value
        const sel = document.getElementById('forecastState') as HTMLSelectElement;
        let infos = response.uniqueValueInfos;
        infos.forEach(function(info) {
          let opt = document.createElement('option');
          opt.text = String(info.value);
          opt.value = String(info.value);
          sel.add(opt, null);
        });
      });
    }

    function getUniqueDeviceName() {
      uniqueValues({
        layer: geojsonLayer,
        field: 'device_name',
      }).then(function(response) {
        // prints each unique value and the count of features containing that value
        const sel = document.getElementById('deviceType') as HTMLSelectElement;
        let infos = response.uniqueValueInfos;
        infos.forEach(function(info) {
          let opt = document.createElement('option');
          opt.text = String(info.value);
          opt.value = String(info.value);
          sel.add(opt, null);
        });
      });
    };

    function updateFeatureTable() {
      let siteId = (document.getElementById('siteId') as HTMLInputElement).value;
      let selectedState = (document.getElementById('forecastState') as HTMLInputElement).value;
      let state_expression = '';

      if (siteId === '' && selectedState === 'all') {
        state_expression = '';
      } else if (selectedState != 'all' && siteId != '') {
        state_expression = 'state = \'' + selectedState + '\' and f_deviceId = \'' + siteId + '\'';
      } else if (selectedState != 'all' && siteId === '') {
        state_expression = 'state = \'' + selectedState + '\'';
      } else {
        state_expression = 'f_deviceId = \'' + siteId + '\'';
      }
      geojsonLayer.definitionExpression = state_expression;
      geojsonLayer2.definitionExpression = state_expression;

      let device_type = (document.getElementById('deviceType') as HTMLInputElement).value;
      if (device_type === 'all') {
        console.log(device_type);
      } else if (state_expression === '') {
        geojsonLayer.definitionExpression = 'device_name = \'' + device_type + '\'';
        geojsonLayer2.definitionExpression = 'device_name = \'' + device_type + '\'';
      } else {
        geojsonLayer.definitionExpression = state_expression + ' and device_name = \'' + device_type + '\'';
        geojsonLayer2.definitionExpression = state_expression + ' and device_name = \'' + device_type + '\'';
      }
    }

    // Render GeoJSON Layer 1
    function generateRenderer1() {
      const typeParams = {
        layer: geojsonLayer,
        view: view,
        field: 'kind',
        legendOptions: {
          title: 'Kind',
        },
      };
      typeRendererCreator
        .createRenderer(typeParams)
        .then((response) => {
          geojsonLayer.renderer = response.renderer;
          if (!map.layers.includes(geojsonLayer)) {
            map.add(geojsonLayer);
          }
        })
        .catch((error) => {
          console.error('there was an error: ', error);
        });
    }

    function generateRenderer2() {
      const typeParams = {
        layer: geojsonLayer2,
        view: view,
        field: 'state',
        legendOptions: {
          title: 'Status',
        },
      };
      typeRendererCreator
        .createRenderer(typeParams)
        .then((response) => {
          geojsonLayer2.renderer = response.renderer;
          if (!map.layers.includes(geojsonLayer2)) {
            map.add(geojsonLayer2);
          }
        })
        .catch((error) => {
          console.error('there was an error: ', error);
        });
    }

    function generateRenderer3() {
      const typeParams = {
        layer: nerc_region,
        view: view,
        field: 'NAME',
        legendOptions: {
          title: 'NERC Region',
        },
      };
      typeRendererCreator
        .createRenderer(typeParams)
        .then((response) => {
          nerc_region.renderer = response.renderer;
        })
        .catch((error) => {
          console.error('there was an error: ', error);
        });
    }

    function clearTextbox() {
      const sel = document.getElementById("siteId") as HTMLSelectElement;
      for (let i = sel.options.length; i >= 0; i--) {
        sel.remove(i);
      }
      updateFeatureTable();
    }

    // map.add(populationLayer);
    map.add(weatherLayer);
    map.add(weatherLayerTS);
    map.add(pm25Layer);
    map.add(smoke_forecast);
    map.add(weather_station);
    map.add(precip_forecast);
    map.add(fire_incident);
    map.add(events_ordered_by_size);
    map.add(nerc_region);
    map.add(cummulativeRain);
    map.add(streamgauge);
    map.add(transmission);
    map.add(transmission_dc);
    map.add(bess_sites);

    // Generate the renderer when the view becomes ready
    reactiveUtils
      .whenOnce(() => !view.updating)
      .then(() => {
        generateRenderer1(); // Forecast Kind
        generateRenderer2(); // Forecast Status
        generateRenderer3(); // NERC Region
        // generateRenderer4(); // Transmission Lines
        getUniqueDeviceName();
        getUniqueStatus();
      });
    this.view = view;
    return this.view.when();
  };

  ngOnDestroy(): void {
    if (this.view) {
      this.view.destroy();
    }
  };

}
